import _objectSpread from "/Users/jason/dev/bidatc/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
var FacilityRepository = RepositoryFactory.get('facilities');
export default {
  name: 'facility-setup',
  components: {},
  props: {},
  data: function data() {
    return {};
  },
  methods: {
    /** Activate a new facility */
    activateFacility: function activateFacility() {
      var _this = this;

      FacilityRepository.activate(this.facility.id).then(function (r) {
        _this.$store.commit('facility/setFacility', r.data);

        _this.setFacility(_this.$store.state.facility.facility);
      });
    }
  },
  computed: _objectSpread({}, mapGetters({
    facility: 'facility/facility',
    is: 'auth/is'
  }))
};