import _objectSpread from "/Users/jason/dev/bidatc/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters } from 'vuex';
import Sidebar from '../../components/stateless/SidebarPlugin/SideBar';
import UserMenu from './UserMenu';
export default {
  name: 'sidebar',
  components: {
    Sidebar: Sidebar,
    UserMenu: UserMenu
  },
  data: function data() {
    return {
      logo: require('@/assets/img/logos/natca-icon-light-35x35.png')
    };
  },
  methods: {
    role: function role(_role) {
      return this.$store.getters['auth/hasRole'](this.facility.id, _role);
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters({
    facility: 'facility/facility',
    facilitySet: 'facility/isSet',
    areas: 'area/areas',
    area: 'area/area',
    areaSet: 'area/isSet',
    is: 'auth/is',
    user: 'auth/user'
  })), {}, {
    facilityRouteParams: function facilityRouteParams() {
      return {
        facility: this.facility.id
      };
    },
    areaRouteParams: function areaRouteParams() {
      return {
        facility: this.facility.id,
        area: this.area.slug
      };
    }
  })
};