import _objectSpread from "/Users/jason/dev/bidatc/dashboard/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.starts-with.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import { mapGetters } from 'vuex';
import { Alerts, SuperModal } from '@/components';
import NavbarTop from './_Navbar.vue';
import Subheader from './_Subheader.vue';
import UserBidder from './UserBidder';
import ActiveBidderBar from './ActiveBidderBar';
import BidderLeaveBar from '@/views/Layout/BidderLeaveBar';
import TestMode from './TestMode';
import { ZoomCenterTransition } from 'vue2-transitions';
import Sidebar from './_Sidebar.vue';
import CustomFooter from './_Footer';
import BiddingClosed from '@/views/Layout/BiddingClosed';
import FacilitySetup from '@/views/Layout/FacilitySetup';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(".".concat(className));
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(function () {
      initScrollbar(className);
    }, 100);
  }
}
/**
     * Retrieve eligible bidders and update store
     * @param data
     * @param $store
     */


function fetchEligibleBidders(data, $store) {
  // Fetch the updated eligible bidders
  BidderRepository.fetchEligibleBidders(data.bidder.id, data.skipToEnd).then(function (r) {
    var bidders = r.data; // Loop through each eligible bidder and update the state

    _.forEach(bidders, function (bidder) {
      var payload = {
        bidder_id: bidder.id,
        changes: bidder
      };
      $store.commit('bidder/updateBidder', payload);
    });
  });
}

function manageActiveBidder(bidder, activate, $store) {
  // Format the bidder for the store
  var payload = {
    bidder_id: bidder.id,
    changes: bidder
  }; // Update the store

  $store.commit('bidder/updateBidder', payload); // Check if activating or deactivating

  if (activate) {
    // Activate bidder and start timer
    $store.dispatch('bidder/startBidderTimer', bidder);
  } else {
    // Deactivate bidder and stop timer
    $store.dispatch('countdown/stop');
  }
}

var BidderRepository = RepositoryFactory.get('bidder');
export default {
  components: {
    FacilitySetup: FacilitySetup,
    BiddingClosed: BiddingClosed,
    CustomFooter: CustomFooter,
    Alerts: Alerts,
    SuperModal: SuperModal,
    Subheader: Subheader,
    UserBidder: UserBidder,
    ActiveBidderBar: ActiveBidderBar,
    BidderLeaveBar: BidderLeaveBar,
    TestMode: TestMode,
    ZoomCenterTransition: ZoomCenterTransition,
    Sidebar: Sidebar,
    NavbarTop: NavbarTop
  },
  data: function data() {
    return {
      logo: require('@/assets/img/logos/natca-logo-white-bg.png'),
      channel: null
    };
  },
  methods: {
    toggleSidebar: function toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  },
  computed: _objectSpread(_objectSpread({}, mapGetters({
    facility: 'facility/facility',
    isFacilityOpen: 'facility/isFacilityOpen',
    areas: 'area/areas',
    area: 'area/area',
    is: 'auth/is',
    mocking: 'auth/mocking',
    bidMode: 'bidder/bidMode',
    bidder: 'bidder/active'
  })), {}, {
    showAreaSubheader: function showAreaSubheader() {
      return !_.includes(['xs', 'sm'], this.screenSize);
    }
  }),
  created: function created() {},
  mounted: function mounted() {
    var _this = this;

    var docClasses = document.body.classList;
    var isWindows = navigator.platform.startsWith('Win');

    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function
      initScrollbar('sidebar');
      initScrollbar('sidebar-wrapper');
      docClasses.add('perfect-scrollbar-on');
    } else {
      docClasses.add('perfect-scrollbar-off');
    } // Subscribe to Pusher for Active Bidder


    this.channel = this.$pusher.subscribe(this.facility.id); // Update hours

    this.channel.bind('hours', function (r) {
      _this.$store.commit('facility/setHours', r.facility.today);
    }); // Make sure an area is set before trying to bind

    if (this.area) {
      // Activate bidder
      this.channel.bind('bidder-' + this.area.id, function (data) {
        manageActiveBidder(data.bidder, data.shouldActivate, _this.$store);
      }); // Bind to update Eligible Bidders

      this.channel.bind('eligible-bidders-' + this.area.id, function (data) {
        fetchEligibleBidders(data, _this.$store);
      });
    }
  },
  watch: {
    area: function area(n, o) {
      var _this2 = this;

      // Unbind the old area
      this.channel.unbind('eligible-bidders-' + o.id);
      this.channel.unbind('bidder-' + o.id); // Bind to the new area

      this.channel.bind('eligible-bidders-' + n.id, function (data) {
        fetchEligibleBidders(data, _this2.$store);
      }); // Activate bidder

      this.channel.bind('bidder-' + this.area.id, function (data) {
        manageActiveBidder(data.bidder, data.shouldActivate, _this2.$store);
      });
    },
    facility: function facility(n, o) {
      // Unsubscribe from the old facility
      if (o.id) {
        this.channel = this.$pusher.unsubscribe(o.id);
      } // Subscribe to the new facility


      if (n.id) {
        this.channel = this.$pusher.subscribe(n.id);
      }
    }
  }
};